import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nav"];

  toggle() {
    document.body.classList.toggle("overflow-hidden");
    for (let el of this.navTargets) {
      el.classList.toggle("initial-state");
      el.classList.toggle("translate-x-0");
      el.classList.toggle("-translate-x-full");
    }
  }
}