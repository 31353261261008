import { Controller } from "stimulus";
import VMasker from "vanilla-masker";

export default class extends Controller {
  execute(event) {
    const element = event.target;
    element.value = VMasker.toPattern(
      element.value,
      element.dataset.mask
    );
  }

  decimal(event) {
    const element = event.target;
    if (element.value != '') {
      element.value = VMasker.toMoney(
        element.value
      )
    }
  }
}